import $ from 'jquery';
import getCookie from './getCookie';
import setCookie from './setCookie';
import removeCookie from './removeCookie';

export default function cookies () {
    // https://codepen.io/Vento/pen/GpvzjO
	// Button ID
	var buttonId = '#cookie-understand';
	// Cookie name
	var cookieName = 'cookies';
	// Cookie value
	var cookieValue = 'le-cookie';
	// Cookie expire (minutes)
	var cookieExpire = $('.cookie-bar').data('expiry');

	// When click button, create cookie.
	$(document).ready(function(){
		$(buttonId).click(function() {
			if (getCookie(cookieName) == null){

				// Create the cookie.
				setCookie(cookieName, cookieValue, cookieExpire);

				// Hide the alert message.
				$('.cookie-bar').removeClass('cookie-bar--active');
			}
		});

		if (getCookie(cookieName) == null) {
			// No cookie = Show cookie bar
			$('.cookie-bar').addClass('cookie-bar--active');
	  	}

		$('body').on('click', '#delete-cookie', function() {

			// Delete the cookie
			removeCookie(cookieName);

			if (getCookie(cookieName) == null) {
				// No cookie = Show cookie bar
				$('.cookie-bar').addClass('cookie-bar--active');
		  	}
		});

	});
}
